<template>
	<safeguard-table-with-filter
		report-link="reports/training-raise-awareness-details"
		:table-headers="[
			{ key: 'project_id' },
			{ key: 'type_of_training' },
			{ key: 'created_at', label: 'Date of training' },
			{ key: 'number_of_trained_persons_males' },
			{ key: 'number_of_trained_persons_females' },
			{ key: 'details' },
		]"
	/>
</template>

<script>
import SafeguardTableWithFilter from '../components/SafeguardTableWithFilter.vue';

export default {
	name: 'TrainingRaiseAwarenessDetails',
	components: { SafeguardTableWithFilter },
};
</script>
